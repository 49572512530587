<template>
<el-card shadow="never">
  <el-form
      :model="ruleForm"
      :rules="rules"
      status-icon
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
  >
    <el-form-item label="用户名称" prop="userName">
      <el-input v-model="ruleForm.userName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="用户昵称" prop="nickName">
      <el-input v-model="ruleForm.nickName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="用户邮箱" prop="userMail">
      <el-input v-model="ruleForm.userMail"></el-input>
    </el-form-item>
    <el-form-item label="用户密码" prop="userPass">
      <el-input
          type="password"
          v-model.number="ruleForm.userPass"
          show-password
      ></el-input>
    </el-form-item>

    <el-form-item label="用户类别" prop="userType">
      <el-select v-model="ruleForm.value" placeholder="请选择" >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="用户所属客户" prop="customerId">
      <el-select v-model="ruleForm.usercustomer" placeholder="请选择" >
        <el-option
            v-for="item in usercustomeroptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="addUser('ruleForm')" style="background: #1d90ff; border:none" :disabled="this.show">提交</el-button>
    </el-form-item>
  </el-form>
</el-card>
</template>
<script>
import $ from "jquery";
export default {
  data() {
    return {
       rules: {
        userName: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        nickName: [
          { required: true, message: "请输入用户昵称", trigger: "blur" },
        ],
        userMail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          // { pattern:/^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: "请输入正确的邮箱", trigger: "blur"}

        ],
        userPass: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        //  userType: [
        //   { required: true, message: "请选择类型", trigger: "blur" },
        // ],
       
      },
       show:false,
      ruleForm: {
        userName: "",
        nickName: "",
        userMail: "",
        userPass: "",
        value: "",
        usercustomer:'',
      },
      usercustomeroptions:[{id:0,name:"公司员工"}],
      options: [
        {
          
          value: 0,
          label: "撰稿人",
        },
        {
          value: 1,
          label: "运营",
        },
        {
          value: -99,
          label: "管理者",
        },
      ],
      
    };
  },
  methods: {
    addUser(ruleForm) {
      this.show = true;
      // if(this.ruleForm.usercustomer == null){
      //   this.ruleForm.usercustomer = 0

      // }
      // console.log(this.$refs[ruleForm]);
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      console.log('用户类型'+this.ruleForm.value)
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          console.log('用户类型'+this.ruleForm.value)
          this.$api.post(
        "/user/save",
        null,
        {
          userName: this.ruleForm.userName,
          nickName: this.ruleForm.nickName,
          userMail: this.ruleForm.userMail,
          userPass: this.ruleForm.userPass,
          userType: this.ruleForm.value,
          customerId:this.ruleForm.usercustomer,
        },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              
              this.$router.push({name: "nameList"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
          
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    },
    getcustomer() {
      this.$api.get(
        "/customer/list",
        null,
        {
          list: false,
        },
        (successRes) => {
           let t = successRes.data.rows.length;
         
         for(let i = 0;i<t;i++){
           this.usercustomeroptions.push(successRes.data.rows[i]);
           console.log(successRes.data.rows[i]);

         }
         

          // this.usercustomeroptions.push();
          console.log(this.usercustomeroptions);
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
  },
  mounted(){
    this.getcustomer();
  }
};
</script>